body {
  margin: 0;
  padding: 2rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.nav-links {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  margin-bottom: 15px;
  /* border: 1px solid #e7e7e7; */
  /* background-color: #f3f3f3; */
}

.nav-links li {
  float: left;
}

.nav-links li a {
  display: block;
  color: #666;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}

.nav-links li a:hover:not(.active) {
  background-color: #ddd;
  color: black;
  text-decoration: none;
}

.nav-links li a.active {
  color: white;
  background-color: #4caf50;
  text-decoration: none;
}

.portfolio-nav-links li {
  float: none;
  text-align: left;
}

.portfolio-nav-links li a {
  text-align: left;
}

.portfolio-nav-links {
  margin: 0px;
  display: inline-block;
  border-right: 2px solid lightgrey;
  padding-right: 20px;
}

#portfolio-links {
  margin-bottom: 15px;
  display: block;
}

#portfolio-links a {
  color: steelblue;
  text-decoration: underline;
  padding: 5px;
  margin-top: 5px;
}

#portfolio-links a:first-child {
  padding-left: 0px;
}

.bold {
  font-weight: bold;
}

.time {
  position: absolute;
  left: -110px;
  top: -1px;
}

.timeline-wrapper {
  margin-top: 20px;
  padding-left: 60px;
  min-width: 400px;
  font-family: "Helvetica";
  font-size: 14px;
}

.timeline-wrapper strong {
  font-size: 16px;
  margin-left: -65px;
}

.timeline {
  position: relative;
  padding-left: 45px;
  list-style: none;
  margin-top: 10px;
}

.timeline::before {
  display: inline-block;
  content: "";
  position: absolute;
  top: 0;
  left: 15px;
  width: 10px;
  height: 100%;
  border-left: 2px solid #ccc;
}

.timeline-item {
  position: relative;
  counter-increment: list;
}

.timeline-item:not(:last-child) {
  padding-bottom: 20px;
}

.timeline-item::before {
  display: inline-block;
  content: "";
  position: absolute;
  left: -30px;
  height: 100%;
  width: 10px;
}

.timeline-item::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 5px;
  left: -34px;
  width: 10px;
  height: 10px;
  border: 2px solid #ccc;
  border-radius: 50%;
  background-color: #fff;
}

.timeline-item.is-done::before {
  border-left: 2px solid #4caf50;
}

.timeline-item.is-done::after {
  font-size: 10px;
  color: #fff;
  text-align: center;
  border: 2px solid #4caf50;
  background-color: #4caf50;
}

.timeline strong {
  display: block;
}

.flex-container {
  display: flex;
  flex-wrap: wrap;
}

#about {
  margin-right: 60px;
  margin-top: 20px;
}

.project-container {
  margin-top: 20px;
}

.project-link {
  color: steelblue;
  text-decoration: underline;
  font-size: 22px;
}

.project-description {
  /* border-left: 2px solid lightgrey; */
  margin-left: 20px;
  margin-top: 5px;
  vertical-align: top;
  display: inline-block;
}

.project-description ul li {
  margin: 15px 0px;
}

.strikethrough {
  text-decoration: line-through;
}

.italic {
  font-style: italic;
}

.skills {
  display: inline-block;
}